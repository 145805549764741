<template>
  <v-container>
    <v-layout row>
      <v-flex xs10>
        <v-autocomplete
          ref="volumeInput"
          v-model="currentVolume"
          :items="items"
          :search-input.sync="search"
          cache-items
          hide-no-data
          hide-details
          @keyup.enter="_updateCurrentVolume"
          label="Volume para embarcar"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs2>
        <v-btn
          top
          :disabled="!currentVolume"
          color="primary"
          small
          :loading="loading"
          @click="_shipVolume"
        >
          Embarcar Volume
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    volumeToShip: { type: Number }
  },
  data: () => ({
    loading: false,
    items: [],
    search: null,
    currentVolume: null,
    piecesToPack: []
  }),

  mounted() {
    const piecesToPackInput = this.$refs.volumeInput;
    piecesToPackInput.focus();
  },
  computed: {
    ...mapState({
      volumes: (state) => state.volume.volumes
    })
  },
  methods: {
    ...mapActions({
      _listVolumes: 'listVolumes',
      _ship: 'shipVolume'
    }),
    async _shipVolume() {
      try {
        this.loading = true;
        const filtered = this.volumes.filter(
          (volume) => String(volume['volumeId']) === String(this.currentVolume)
        );
        if (filtered.length > 0) {
          await this._ship(filtered[0]);
        }
        this.currentVolume = null;
        await this._listVolumes(this.$route.params.id);
      } catch (e) {
        console.log(e);
        this.snackbarText =
          'Ocorreu um erro tentando embarcar o volume. Tente novamente.';
        this.showSnackbar = true;
      }
      this.loading = false;
    },
    async _updateCurrentVolume(e) {
      if (this.currentVolume) {
        await this._shipVolume();
      }
    },
    querySelections(v) {
      const volumeIds = this.volumes.map((volume) =>
        String(volume['volumeId'])
      );
      this.items = volumeIds.filter((volumeId) =>
        volumeId.startsWith(String(v) || '')
      );
      const exact = volumeIds.filter((volumeId) => volumeId === String(v));
      if (exact.length > 0) {
        this.currentVolume = exact[0];
        this.items.push(this.currentVolume);
      }
    }
  },
  watch: {
    volumeToShip(newValue) {
      if (newValue) {
        this.search = newValue;
      }
    },
    search(val) {
      val && val !== this.currentVolume && this.querySelections(val);
    }
  }
};
</script>
